@font-face {
  font-family: 'TT DemiBold';
  font-weight: 600;
  font-style: normal;
  src: url('../assets/fonts/TTCommons-DemiBold.ttf');
}


img {
  max-width: 100%;
  height: auto;
}
